* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*font-family: "Lato","Arial",sans-serif*/
  }
  form {
    display: block;
    position: relative;
  }
  .loginform {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color:#0d8074;
    padding: 60px 10px;
    overflow: auto;
    position: fixed;
  }
  .errormessage {
    color: red;
  }
  /*.App{
      height: 100%;
      width: 100%;
      align-items: center;
      justify-content: center;
      background-color: #3d4c5a;
      padding: 60px 10px;
      overflow: auto;
      position: fixed;
  }*/
  
  .headclass {
    text-align: center;
    color: white;
    font-weight: 400;
    margin-bottom: 40px;
  }
  
  .headclass span {
    color: #01c0c8;
  }
  
  .form-inner h2 {
    font-size: 30px;
    color: #00c292;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .form-inner {
    background: white;
    border-radius: 5px !important;
    margin: auto;
    padding: 20px !important;
    width: 360px;
  }
  
  .forgot_form_width {
    width: 400px;
  }
  .forgot_form_head {
    text-align: center;
  }
  
  .form-inner-admin {
    background: white;
    border-radius: 5px !important;
    /* padding: 0 20px !important; */
  }
  
  .form-group {
    margin-top: 15px;
  }
  .creatadmin {
    margin-top: 20px;
  }
  
  .creatadmin2 {
    margin-top: 20px;
  }
  
  .forminput {
    height: 40px;
    width: 100%;
  }
  .forminput2 {
    width: 100%;
    padding-top: 15px;
  }
  
  .form-group input {
    font-size: 16px;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
  }
  
  .form-group select {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: groove;
  }
  
  .form-group input::placeholder {
    font-size: 12px;
  }
  
  .form-group label {
    font-size: 15px;
  }
  
  .text-left {
    text-align: left !important;
  }
  .inline-block {
    display: inline-block !important;
  }
  .checkbox-custom,
  .checkbox-custom-alt {
    cursor: pointer;
  }
  .checkbox-custom input,
  .checkbox-custom-alt input {
    opacity: 0.5;
  }
  .btn.btn-dutch {
    width: 100%;
    height: 35px;
    font-size: 20px;
    background-color: #01c0c8;
    color: white;
    border-color: #01a8af;
    border-radius: 5px;
  }
  .btn.btn-dutch2 {
    width: 100%;
    height: 35px;
    background-color: #0069d9;
    color: white;
    border-color: #0069d9;
    border-radius: 5px;
  }
  
  .regdbtn {
    width: 20%;
    border-radius: 10px;
    font-size: 0px;
    background-color: #01c0c8;
    color: white;
    border-color: #01a8af;
  }
  
  .mt-20 {
    margin-top: 20px !important;
  }
  .mt-80 {
    margin-top: 80px !important;
  }
  .forgotclass {
    font-size: 13px;
    color: #23527c;
  }
  .cust-btn {
    padding: 8px 23px;
    font-size: 20px;
  }
  
  .editprofilebtn {
    padding: 10px 15px;
    margin-left: 20px;
  }
  
  .editprofilebtn2 {
    padding: 10px 15px;
    margin-top: 20px;
  }
  
  .mt-20crtofc {
    margin-top: 20px !important;
    padding: 10px 15px;
  }
  